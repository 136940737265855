import { SearchResultsSettingsTab } from '../../components/SearchResults/Settings/types';
import { navigateToSearchResultsPage } from '../searchResults';
import { openSearchResultsWidgetSettingsModal } from '../searchResultsWidgetSettings';
import { EditorAppContext } from '../types';
import { AppManagerAction } from './getAppManagerConfig';

export type AppManagerActionHandler = (
  action: AppManagerAction,
) => Promise<void>;

export function appManagerActionFactory(
  appContext: EditorAppContext,
): AppManagerActionHandler {
  const { handleException } = appContext;
  const handlers = {
    async [AppManagerAction.CustomizeSearchResults]() {
      await navigateToSearchResultsPage(appContext);

      await openSearchResultsWidgetSettingsModal(appContext);
    },
    async [AppManagerAction.CustomizeDesign]() {
      await navigateToSearchResultsPage(appContext);

      await openSearchResultsWidgetSettingsModal(
        appContext,
        SearchResultsSettingsTab.Design,
      );
    },
    // async [AppManagerAction.AddSearchBox]() {
    //   await editorSDK.editor.deeplink.show(appDefinitionId, {
    //     type: 'addPanel',
    //     params: [appDefinitionId],
    //   });
    // },
  };
  return async action => {
    try {
      await handlers[action]();
    } catch (ex) {
      handleException(ex);
    }
  };
}
