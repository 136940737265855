import { IGetDataByAppDef } from '@wix/platform-editor-sdk/lib/js/modules/document/tpa/app';

import { SEARCH_SETTINGS_TAB_PARAM } from '../../components/SearchResults/Settings/constants';
import { SearchResultsSettingsTab } from '../../components/SearchResults/Settings/types';
import { SEARCH_RESULTS_WIDGET_ID } from '../../constants';
import { EditorAppContext } from '../types';

export async function openSearchResultsWidgetSettingsModal(
  appContext: EditorAppContext,
  activeSearchSettingsTab: SearchResultsSettingsTab = SearchResultsSettingsTab.Main,
) {
  const { editorSDK, appDefinitionId, translate } = appContext;

  const appData = await editorSDK.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId,
  );

  const [urlPrefix, componentRef, currency, locale] = await Promise.all([
    getSettingsModalUrl(appData),
    getComponentRef(appContext, appData),
    editorSDK.info.getCurrency(appDefinitionId),
    editorSDK.environment.getLocale(appDefinitionId),
  ]);

  if (componentRef && urlPrefix) {
    const width = 404;
    const url = `${urlPrefix}?${[
      `${SEARCH_SETTINGS_TAB_PARAM}=${activeSearchSettingsTab}`,
      `appDefinitionId=${appDefinitionId}`,
      `applicationId=${appData.applicationId}`,
      `compId=tpaSettings`,
      `currency=${currency}`,
      `instance=${appData.instance}`,
      `locale=${locale}`,
      `origCompId=${componentRef.id}`,
      `viewMode=editor`,
      `width=${width}`,
    ].join('&')}`;

    await editorSDK.editor.selection.selectComponentByCompRef(appDefinitionId, {
      compsToSelect: [componentRef],
    });

    await editorSDK.editor.openComponentPanel(appDefinitionId, {
      componentRef,
      height: 528,
      title: translate('searchResults.settings.modal.title'),
      type: editorSDK.editor.PanelType.Settings,
      url,
      width,
    });
  }
}

const getSettingsModalUrl = (
  appData: IGetDataByAppDef & {
    widgets?: {
      [key: string]: { settings?: { url?: string; urlV2?: string } };
    };
  },
): string | undefined => {
  const widgetSettings = appData?.widgets?.[SEARCH_RESULTS_WIDGET_ID]?.settings;

  if (widgetSettings) {
    return widgetSettings.urlV2 || widgetSettings.url;
  }
};

const getComponentRef = async (
  { editorSDK, appDefinitionId }: EditorAppContext,
  appData: IGetDataByAppDef,
) => {
  const appComponents = await editorSDK.tpa.app.getAllCompsByApplicationId(
    appDefinitionId,
    appData.applicationId,
  );
  const component = appComponents.find(
    c => c.widgetId === SEARCH_RESULTS_WIDGET_ID,
  );
  if (component) {
    return editorSDK.document.components.getById(appDefinitionId, {
      id: component.id,
    });
  }
};
