import { createSettingsClient } from '@wix/search-settings-client';

type SettingsClient = ReturnType<typeof createSettingsClient>;
type GetSavedSettingsResponse = ReturnType<SettingsClient['getSaved']>;

type AppApi = {
  getSavedSettings(options: {
    instance: string;
    host: string;
  }): GetSavedSettingsResponse;
  saveSettings(
    options: { instance: string; host: string },
    key: string,
    value: unknown,
  ): Promise<void>;
};

export const createAppApi = (): AppApi => {
  return {
    getSavedSettings({ instance, host }) {
      const settingsClient = createSettingsClient({
        instance,
        host,
        reportError: (e): void => {
          throw e;
        },
      });
      return settingsClient.getSaved();
    },
    saveSettings({ instance, host }, key, value) {
      const settingsClient = createSettingsClient({
        instance,
        host,
        reportError: (e): void => {
          throw e;
        },
      });
      return settingsClient.set(key, value);
    },
  };
};
