import { ComponentRef, PageRef } from '@wix/platform-editor-sdk';

import {
  addSearchAppController,
  connectControllerWithSearchBox,
  connectControllerWithSearchResults,
} from '../searchAppController';

import {
  addSearchResultsHeader,
  getSearchResultsComponent,
  getSearchResultsPage,
  updateSearchResultsPageData,
} from '../searchResults';

import { addSearchBox } from '../searchBox';
import { EditorAppContext } from '../types';
import { FedopsInteraction } from '../constants/interaction';

export async function doFirstInstall(appContext: EditorAppContext) {
  const { experiments, fedops, editorSDK, appDefinitionId } = appContext;
  fedops.interactionStarted(FedopsInteraction.InstallApp);

  const searchBoxRef = await addSearchBox(appContext);

  const [searchResultsRef, searchResultsPageRef] = await Promise.all<
    ComponentRef | undefined,
    PageRef
  >([getSearchResultsComponent(appContext), getSearchResultsPage(appContext)]);

  if (searchResultsRef) {
    await addSearchResultsHeader(appContext, {
      searchResultsPageRef,
      searchResultsRef,
    });
  }

  await updateSearchResultsPageData(appContext, {
    searchResultsPageRef,
  });

  const searchAppControllerRef = await addSearchAppController(appContext);

  const promises = [];

  if (
    searchBoxRef &&
    !experiments.enabled('specs.siteSearch.ShouldWrapWithAppWidget')
  ) {
    promises.push(
      connectControllerWithSearchBox(
        appContext,
        searchAppControllerRef,
        searchBoxRef,
      ),
    );
  }

  if (searchResultsRef) {
    promises.push(
      connectControllerWithSearchResults(
        appContext,
        searchAppControllerRef,
        searchResultsRef,
      ),
    );
  }

  await Promise.all(promises);

  await editorSDK.pages.navigateTo(appDefinitionId, {
    pageRef: searchResultsPageRef,
  });

  if (searchBoxRef) {
    await editorSDK.selection.selectComponentByCompRef(appDefinitionId, {
      compsToSelect: [searchBoxRef],
    });
  }

  fedops.interactionEnded(FedopsInteraction.InstallApp);
}
