import { AppDescriptor } from '@wix/platform-editor-sdk';

import { EditorAppContext } from '../types';

export enum AppManagerAction {
  // AddSearchBox = 'addSearchBox',
  CustomizeDesign = 'customizeDesign',
  CustomizeSearchResults = 'customizeSearchResults',
}

export function getAppManagerConfig({
  translate,
}: EditorAppContext): { appDescriptor: AppDescriptor } {
  return {
    appDescriptor: {
      mainActions: [
        {
          actionId: AppManagerAction.CustomizeSearchResults,
          icon: 'appManager_settingsAction',
          title: translate('appManager.actions.customizeSearchResults.label'),
        },
      ],
      customActions: [
        {
          actionId: AppManagerAction.CustomizeDesign,
          icon: 'customizeDesignButtonBrush',
          title: translate('appManager.actions.customizeDesign.label'),
          type: 'editorActions',
        },
        // {
        //   actionId: AppManagerAction.AddSearchBox,
        //   icon: 'appManager_addElementsAction',
        //   title: translate('appManager.actions.addSearchBox.label'),
        //   type: 'editorActions',
        // },
      ],
      defaultActions: {
        learnMoreKB: '1d4824c6-d3b7-4bbd-bc1e-25910dfc27e3',
      },
    },
  };
}
