import { ComponentRef } from '@wix/platform-editor-sdk';

import { setDemoSuggestions } from './setDemoSuggestions';
import {
  getAppWidgetWrappedSearchBoxDefinition,
  getSearchBoxComponentDefinition,
} from './componentDefinitions';
import { ComponentType, ComponentLayout, EditorAppContext } from './types';
import { getComponentsByType } from './editorSDKHelpers';

interface SearchBoxData {
  suggestionsEnabled?: boolean;
}

const SEARCH_BOX_SIZE = {
  width: 305,
  height: 45,
};

export async function getAllSearchBoxes(
  appContext: EditorAppContext,
): Promise<ComponentRef[]> {
  try {
    return await getComponentsByType(appContext, ComponentType.SearchBox);
  } catch (ex) {
    appContext.sentry.captureException(ex);
  }
  return [];
}

export async function isSearchBox(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
): Promise<boolean> {
  const { appDefinitionId, editorSDK } = appContext;
  try {
    const componentType = await editorSDK.components.getType(appDefinitionId, {
      componentRef,
    });
    return componentType === ComponentType.SearchBox;
  } catch (ex) {
    appContext.sentry.captureException(ex);
  }
  return false;
}

export async function addSearchBox(
  appContext: EditorAppContext,
): Promise<ComponentRef | undefined> {
  const { editorSDK, experiments, appDefinitionId, sentry } = appContext;
  const headerRef = await editorSDK.siteSegments.getHeader(appDefinitionId);
  const headerLayout = (await editorSDK.components.layout.get(appDefinitionId, {
    componentRef: headerRef,
  })) as ComponentLayout;

  // TODO: handle (headerLayout.bounding.height < searchBoxSize.height) correctly
  const searchBoxLayout = {
    x: headerLayout.bounding.width - SEARCH_BOX_SIZE.width,
    y:
      headerLayout.bounding.height > SEARCH_BOX_SIZE.height
        ? Math.round(
            headerLayout.bounding.height / 2 - SEARCH_BOX_SIZE.height / 2,
          )
        : 0,
    width: SEARCH_BOX_SIZE.width,
    height: SEARCH_BOX_SIZE.height,
  };

  const getSearchBoxDefinition = experiments.enabled(
    'specs.siteSearch.ShouldWrapWithAppWidget',
  )
    ? getAppWidgetWrappedSearchBoxDefinition
    : getSearchBoxComponentDefinition;

  try {
    return await editorSDK.components.add(appDefinitionId, {
      pageRef: headerRef,
      componentDefinition: getSearchBoxDefinition(
        {
          appDefinitionId,
          layout: searchBoxLayout,
        },
        appContext,
      ),
    });
  } catch (ex) {
    sentry.captureException(ex);
  }
}

export async function initSearchBoxSuggestions(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
) {
  const { appDefinitionId, editorSDK } = appContext;
  const data: SearchBoxData | undefined = await editorSDK.components.data.get(
    appDefinitionId,
    {
      componentRef,
    },
  );

  if (data?.suggestionsEnabled === undefined) {
    await editorSDK.components.data.update(appDefinitionId, {
      componentRef,
      data: {
        suggestionsEnabled: false,
      },
    });
  }
  return setDemoSuggestions(appContext, componentRef);
}
