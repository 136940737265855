import {
  EditorReadyFn,
  GetAppManifestFn,
  HandleActionFn,
  OnEventFn,
} from '@wix/yoshi-flow-editor';

import { createAppApi } from './appApi';
import { createSentryClient } from './sentryClientFactory';
import {
  ActionType,
  ControllerType,
  EditorAppContext,
  EventType,
} from './types';
import { Interaction } from './constants/interaction';
import { doFirstInstall } from './doFirstInstall';
import { getAllSearchBoxes } from './searchBox';
import { reconnectSearchBoxes } from './reconnectSearchBoxes';
import { patchInputFontProperty } from './patchInputFontProperty';
import { onRemoveApp } from './onRemoveApp';
import { onComponentAddedToStage } from './onComponentAddedToStage';
import {
  getAppManagerConfig,
  appManagerActionFactory,
  AppManagerActionHandler,
} from './appManager';

interface EditorPlatformApp {
  editorReady: EditorReadyFn;
  getAppManifest: GetAppManifestFn;
  handleAction: HandleActionFn;
  onEvent: OnEventFn;
}

export function createPlatformApp(): EditorPlatformApp {
  let appContext: EditorAppContext;
  let appManagerActionHandler: AppManagerActionHandler;

  return {
    async editorReady(editorSDK, appDefinitionId, options, flowAPI) {
      const { firstInstall, initialAppData, monitoring } = options;
      const { experiments, fedops, environment, translations } = flowAPI;

      const sentry = createSentryClient(monitoring, {
        firstInstall,
        metaSiteId: initialAppData.metaSiteId,
        isEditorX: environment.isEditorX,
      });

      // TODO previously customParams were passed - customParams: { isInEditorX: getIsInEditorX() }, should we care anymore?
      fedops.interactionStarted(Interaction.EditorReady);

      appContext = {
        translate: translations.t.bind(translations),
        editorSDK,
        appDefinitionId,
        fedops,
        experiments,
        sentry,
        isEditorX: environment.isEditorX,
        handleException: ex => {
          console.error(ex);
          sentry.captureException(ex);
        },
      };

      appManagerActionHandler = appManagerActionFactory(appContext);

      await editorSDK.editor.setAppAPI(appDefinitionId, createAppApi());

      if (firstInstall) {
        if (experiments.enabled('specs.siteSearch.ConcurrentEditing')) {
          try {
            await editorSDK.document.transactions.runAndWaitForApproval(
              appDefinitionId,
              async () => {
                await doFirstInstall(appContext);
              },
            );
          } catch (ex) {
            sentry.captureException(ex);
          }
        } else {
          await doFirstInstall(appContext);
        }
      }

      const allSearchBoxes = await getAllSearchBoxes(appContext);

      if (!environment.isEditorX) {
        /**
         * This is a hacky way to reconnect 'abandoned' SearchBox'es (probably added by copy-pasting).
         * Investigate if it's still really needed.
         * https://jira.wixpress.com/browse/SER-1310
         */
        await reconnectSearchBoxes(appContext, allSearchBoxes);
      }

      // TODO Should we run this part of code, if none of components registered? i.e. allSearchBoxes.length === 0
      await Promise.all(
        allSearchBoxes.map(sb => patchInputFontProperty(appContext, sb)),
      );
      await editorSDK.document.application.registerToCustomEvents(
        appDefinitionId,
        {
          eventTypes: [EventType.ComponentAddedToStage],
        },
      );
      fedops.interactionEnded(Interaction.EditorReady);
    },
    async getAppManifest() {
      return {
        controllersStageData: {
          [ControllerType.SearchApp]: {
            default: {
              visibility: 'NEVER',
            },
          },
        },
        ...(appContext.experiments.enabled('specs.siteSearch.AppManager')
          ? getAppManagerConfig(appContext)
          : {}),
      };
    },
    async handleAction({ type }) {
      switch (type) {
        case ActionType.RemoveApp: {
          await onRemoveApp(appContext);
          break;
        }
      }
    },
    async onEvent({ eventType, eventPayload }) {
      switch (eventType) {
        case EventType.ComponentAddedToStage: {
          await onComponentAddedToStage(appContext, eventPayload);
          break;
        }
        case EventType.AppActionClicked: {
          await appManagerActionHandler(eventPayload.actionId);
          break;
        }
      }
    },
  };
}
